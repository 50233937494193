import { StaticValues } from "../types";

export const staticValues: StaticValues = {
  coverText:
    "Solicite sua reserva ou faza sua consulta pelo número da Recepção",
  //whatsApp: "+55 79 9988.2442",
  whatsApp: "+55 79 3025.2441",
};

export const whatsAppNumber = "5579999882442";
